import { closeSettingsMenu } from "./settingsMenu";

let isNavMenuOpen = false;

const navMenuButtonOpen = document.getElementById("menuButtonOpen");
const navMenuButtonClose = document.getElementById("menuButtonClose");
const navMenuDiv = document.getElementById("menu");

if (navMenuButtonOpen) {
  navMenuButtonOpen.addEventListener("click", () => {
    isNavMenuOpen = true;
    closeSettingsMenu();
    navMenuDiv!.classList.remove("hidden");
    navMenuButtonOpen.classList.add("hidden");
    navMenuButtonClose!.classList.remove("hidden");
  });
}

function closeNavMenu() {
  navMenuDiv!.classList.add("hidden");
  isNavMenuOpen = false;
  navMenuButtonOpen!.classList.remove("hidden");
  navMenuButtonClose!.classList.add("hidden");
}

export { isNavMenuOpen, closeNavMenu };
