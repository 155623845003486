// ========================
// login.html and signup.html
// ========================

function possiblyDisplayError() {
  const urlParams = new URLSearchParams(window.location.search);
  const error = urlParams.get("error");
  if (error) {
    const errorMessage = document.getElementById(
      "errorMessage"
    ) as HTMLDivElement;
    errorMessage.innerHTML = error;
    errorMessage.style.backgroundColor = "var(--error-color)";
  }
}

function hideServerErrorMessage() {
  const errorMessage = document.getElementById(
    "errorMessage"
  ) as HTMLDivElement;
  if (errorMessage.innerHTML !== "Passwords do not match") {
    errorMessage.innerHTML = "";
    errorMessage.style.backgroundColor = "var(--bg-secondary-color)";
  }
}

function validateSignupForm() {
  const username = document.getElementById("username") as HTMLInputElement;
  const password = document.getElementById("password") as HTMLInputElement;
  const confirmPassword = document.getElementById(
    "confirmPassword"
  ) as HTMLInputElement;
  const errorMessage = document.getElementById(
    "errorMessage"
  ) as HTMLDivElement;
  const submitButton = document.getElementById(
    "submitButton"
  ) as HTMLButtonElement;
  if (
    password.value &&
    confirmPassword.value &&
    password.value !== confirmPassword.value
  ) {
    confirmPassword.setCustomValidity("Passwords do not match");
    if (errorMessage.innerHTML === "") {
      errorMessage.innerHTML = "Passwords do not match";
      errorMessage.style.backgroundColor = "var(--error-color)";
    }
  } else if (
    password.value &&
    confirmPassword.value &&
    username.value &&
    password.value === confirmPassword.value
  ) {
    confirmPassword.setCustomValidity("");
    errorMessage.innerHTML = "";
    errorMessage.style.backgroundColor = "var(--bg-secondary-color)";
    submitButton.disabled = false;
    return;
  }
  submitButton.disabled = true;
}

function validateLoginForm() {
  hideServerErrorMessage();
  const username = document.getElementById("username") as HTMLInputElement;
  const password = document.getElementById("password") as HTMLInputElement;
  const submitButton = document.getElementById(
    "submitButton"
  ) as HTMLButtonElement;
  if (username.value && password.value) {
    submitButton.disabled = false;
    return;
  }
  submitButton.disabled = true;
}

export { possiblyDisplayError };
