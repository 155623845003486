import { Chapter } from "./other/chapter";
import {
  addCollectionsToCollectionSelect,
  Collection,
} from "./other/collection";
import { getAllDocs, getDb } from "./other/db";
import { getUserSettings, UserSettings } from "./other/userSettings";
import { getCookie } from "./other/utils";

function updateAccountStatus() {
  const userSettings = getUserSettings();
  if (!window.location.href.includes("settings.html")) return;
  const username = getCookie("username");
  if (username) {
    document.getElementById("notLoggedIn")!.classList.add("hidden");
    document.getElementById("loggedIn")!.classList.remove("hidden");
    document.getElementById("username")!.innerText = username;
  } else {
    document.getElementById("notLoggedIn")!.classList.remove("hidden");
    document.getElementById("loggedIn")!.classList.add("hidden");
    userSettings.isSynced = false;
    userSettings.save();
  }
  if (userSettings.isSynced) {
    document.getElementById("synced")!.classList.remove("hidden");
    document.getElementById("notSynced")!.classList.add("hidden");
  } else {
    document.getElementById("synced")!.classList.add("hidden");
    document.getElementById("notSynced")!.classList.remove("hidden");
  }
}

function openDeleteCollectionModal() {
  const modal = document.getElementById(
    "deleteCollectionModal"
  ) as HTMLDivElement;
  const deleteButton = document.getElementById(
    "confirmDeleteCollectionButton"
  ) as HTMLButtonElement;
  const collectionNameSpan = document.getElementById(
    "collectionName"
  ) as HTMLSpanElement;
  const select = document.getElementById(
    "collectionSelect"
  ) as HTMLSelectElement;
  const selectedCollection = select.options[select.selectedIndex];
  if (selectedCollection) {
    const collectionName = selectedCollection.text;
    modal.classList.remove("hidden");
    collectionNameSpan.innerHTML = collectionName;
    setTimeout(() => {
      deleteButton.focus();
    }, 0);
  }
}

function closeDeleteCollectionModal() {
  const modal = document.getElementById(
    "deleteCollectionModal"
  ) as HTMLDivElement;
  modal.classList.add("hidden");
}

async function deleteCollection() {
  const collectionId = (
    document.getElementById("collectionSelect") as HTMLSelectElement
  ).value;
  const db = getDb();
  let allDocs = await getAllDocs(true);
  const doc = await db.get(collectionId);
  const deleteCollectionChapters = (
    document.getElementById(
      "deleteCollectionChaptersCheckbox"
    ) as HTMLInputElement
  ).checked;
  if (deleteCollectionChapters) {
    const chapters = allDocs.rows.filter(
      // todo typing
      (row) => row.doc && (row.doc as any).collectionId === collectionId
    );
    chapters.forEach(async (chapter) => {
      if (chapter.doc) db.remove(chapter.doc);
    });
  }
  (
    document.getElementById(
      "deleteCollectionChaptersCheckbox"
    ) as HTMLInputElement
  ).checked = false;
  await db.remove(doc);
  allDocs = await getAllDocs(true);
  let options = document.querySelectorAll("#collectionSelect option");
  options.forEach((option) => option.remove());
  addCollectionsToCollectionSelect();
  closeDeleteCollectionModal();
}

// ========================
// PWA
// ========================

function registerServiceWorker() {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    // return;
  }
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("service-worker.js")
      .then(() => {
        console.log(`service worker registered succesfully.`);
      })
      .catch((err) => {
        console.log(`Error registring ${err}`);
      });
  } else {
    console.log(`Service worker is not supported in this browser.`);
  }
}

let deferredPrompt: any;
async function addInstallButton() {
  const userSettings = getUserSettings();
  if (
    window.matchMedia("(display-mode: standalone)").matches ||
    //@ts-ignore
    window.navigator.standalone === true
  ) {
    // User is currently navigating on the PWA
    if (!userSettings.isPWAInstalled) {
      userSettings.isPWAInstalled = true;
      userSettings.save();
    }
  } else {
    window.addEventListener("beforeinstallprompt", (e) => {
      deferredPrompt = e;
      userSettings.isPWAInstalled = false;
      userSettings.save();
      showInstallPromotion(userSettings);
      document.getElementById("installedContainer")!.classList.add("hidden"); // in case it was shown before
    });
  }
  setTimeout(() => {
    if (!userSettings.isPWAInstalled && !deferredPrompt) {
      document.getElementById("cannotBeInstalled")!.classList.remove("hidden");
    }
    if (userSettings.isPWAInstalled) {
      document.getElementById("installedContainer")!.classList.remove("hidden");
    }
  }, 300);

  // getInstalledRelatedApps doesn't work on macOS
  // https://bugs.chromium.org/p/chromium/issues/detail?id=1114966
  // if ("getInstalledRelatedApps" in window.navigator) {
  //   const relatedApps = await navigator.getInstalledRelatedApps();
  //   relatedApps.forEach((app) => {
  //     //if your PWA exists in the array it is installed
  //     console.log(app.platform, app.url);
  //   });
  // }
}

function showInstallPromotion(settings: UserSettings) {
  const installButton = document.getElementById(
    "installButton"
  ) as HTMLButtonElement;
  installButton.classList.remove("hidden");
  installButton.addEventListener("click", async () => {
    deferredPrompt.prompt();
    const { outcome } = await deferredPrompt.userChoice;
    if (outcome === "accepted") {
      installButton.classList.add("hidden");
      document.getElementById("installedContainer")!.classList.remove("hidden");
      settings.isPWAInstalled = true;
      settings.save();
    }
    deferredPrompt = null;
  });
}

export {
  registerServiceWorker,
  updateAccountStatus,
  addInstallButton,
  closeDeleteCollectionModal,
};
