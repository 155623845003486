import { getAllDocs, getDb } from "./other/db";
import type { Chapter } from "./other/chapter";
import { Collection, createCollection } from "./other/collection";
import { getUserSettings } from "./other/userSettings";
import { cleanText } from "./other/utils";

// ========================
// render
// ========================

function possiblyOpenChooseCollection(open: boolean) {
  const userSettings = getUserSettings();
  const chooseCollectionDiv = document.getElementById(
    "chooseCollectionDiv"
  ) as HTMLDivElement;
  const chooseCollectionButton = document.getElementById(
    "chooseCollectionButton"
  ) as HTMLButtonElement;
  const collectionSelect = document.getElementById(
    "collectionSelect"
  ) as HTMLSelectElement;
  const collectionSelectText = document.getElementById("collectionSelectText");
  if (chooseCollectionDiv) {
    if (open) {
      chooseCollectionDiv.classList.remove("hidden");
      chooseCollectionButton.classList.add("open");
      userSettings.isChooseCollectionOpen = true;
      if (userSettings.selectedCollection) {
        collectionSelect.value = userSettings.selectedCollection;
      }
      if (collectionSelectText) {
        collectionSelectText.innerText = "selected collection:";
      }
    } else {
      chooseCollectionDiv.classList.add("hidden");
      chooseCollectionButton.classList.remove("open");
      userSettings.isChooseCollectionOpen = false;
      if (collectionSelectText) {
        collectionSelectText.innerText =
          "do you want to add this text to a collection? (e.g. a book title)";
      }
    }
  }
}

async function addCurrentChaptersToPage() {
  const collectionIdToNameMap: { [key: string]: { name: string } } = {};
  const chapters: Chapter[] = [];
  const allDocs = await getAllDocs();
  allDocs.rows.forEach((row) => {
    const doc = row.doc as unknown as Chapter | Collection;
    if (doc.type === "chapter") {
      chapters.push(row.doc as unknown as Chapter);
    } else if (doc.type === "collection") {
      collectionIdToNameMap[doc._id] = { name: doc.title };
    }
  });

  const currentChaptersPreviewDiv = document.getElementById(
    "curentChaptersPreview"
  ) as HTMLDivElement;
  currentChaptersPreviewDiv.innerHTML = "";
  chapters.sort((a, b) => {
    return b.updatedAt - a.updatedAt;
  });
  let finishedChapterExists = false;
  chapters.forEach((chapter) => {
    if (
      chapter.text.length ===
      chapter.position + chapter.currentWordPosition
    ) {
      finishedChapterExists = true;
      return;
    }
    let html = `
      <a href="practice.html?id=${chapter._id}" class="currentChapterPreview">
        <div>
          <div class="collectionName">${
            chapter.collectionId && collectionIdToNameMap[chapter.collectionId]
              ? collectionIdToNameMap[chapter.collectionId].name
              : ""
          }</div>
          ${chapter.title}
        </div>
        <div class="currentChapterPreviewWordCount">${Math.floor(
          (chapter.position + chapter.currentWordPosition) / 5
        )}/${Math.floor(chapter.text.length / 5)} words</div>
      </a>`;
    currentChaptersPreviewDiv.innerHTML += html;
  });
  if (chapters.length === 0) {
    document.getElementById("testAppHint")!.classList.remove("hidden");
  }
  if (chapters.length !== 0 && finishedChapterExists) {
    document
      .getElementById("displayAllChaptersLink")!
      .classList.remove("hidden");
  }
}

// ========================
// text input
// ========================

function onPasteStripFormatting(e: ClipboardEvent) {
  if (!e.clipboardData) return;
  e.preventDefault();
  const chapterInputArea = document.getElementById(
    "chapterInputArea"
  ) as HTMLDivElement;
  const caretPos = getCaretCharacterOffsetWithin(chapterInputArea);
  const front = chapterInputArea.innerText.substring(0, caretPos);
  const back = chapterInputArea.innerText.substring(
    caretPos,
    chapterInputArea.innerText.length
  );
  let text = front + e.clipboardData.getData("text/plain") + back;
  text = cleanText(text);
  text = "<div>" + text.replaceAll("\n", "</div><div>") + "</div>";
  chapterInputArea.innerHTML = text;
}

function getCaretCharacterOffsetWithin(element: any) {
  // https://stackoverflow.com/a/4812022
  var caretOffset = 0;
  var doc = element.ownerDocument || element.document;
  var win = doc.defaultView || doc.parentWindow;
  var sel;
  if (typeof win.getSelection != "undefined") {
    sel = win.getSelection();
    if (sel.rangeCount > 0) {
      var range = win.getSelection().getRangeAt(0);
      var preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      console.log(preCaretRange);
      caretOffset = preCaretRange.toString().length;
    }
  } else if ((sel = doc.selection) && sel.type != "Control") {
    var textRange = sel.createRange();
    var preCaretTextRange = doc.body.createTextRange();
    preCaretTextRange.moveToElementText(element);
    preCaretTextRange.setEndPoint("EndToEnd", textRange);
    caretOffset = preCaretTextRange.innerText.length;
  }
  caretOffset += element.innerText.slice(0, caretOffset).split("\n").length - 1;
  return caretOffset;
}

function removePlaceholder() {
  const chapterInputArea = document.getElementById(
    "chapterInputArea"
  ) as HTMLDivElement;
  if (
    chapterInputArea.innerText.includes(
      "paste your text here... (e.g. a book chapter)"
    )
  ) {
    chapterInputArea.innerHTML = "<div><br /></div>";
  }
}

function addPlaceholder() {
  const chapterInputArea = document.getElementById(
    "chapterInputArea"
  ) as HTMLDivElement;
  if (chapterInputArea.innerText.trim() === "") {
    chapterInputArea.innerHTML =
      "<i>paste your text here... (e.g. a book chapter)</i><div><br /></div>";
  }
}

// ========================
// save chapter
// ========================

async function saveChapter(newChapterText = "") {
  const userSettings = getUserSettings();
  const db = getDb();
  let text = newChapterText;
  if (!newChapterText) {
    const textArea = document.getElementById(
      "chapterInputArea"
    ) as HTMLDivElement;
    text = cleanText(textArea.innerText);
  } else {
    text = cleanText(newChapterText);
  }
  const collectionId = userSettings.isChooseCollectionOpen
    ? (document.getElementById("collectionSelect") as HTMLInputElement).value ??
      "other"
    : "other";
  const _id = new Date().toISOString();
  const currentWord_a = text.split(" ")[0];
  const currentWord_b = text.split("\n")[0];
  const currentWord =
    currentWord_a.length < currentWord_b.length ? currentWord_a : currentWord_b;

  const newChapter: Chapter = {
    _id,
    _rev: "",
    text,
    currentWord,
    position: 0,
    currentWordPosition: 0,
    lastWord: "",
    title: currentWord_b,
    collectionId,
    type: "chapter",
    createdAt: Date.now(),
    updatedAt: Date.now(),
    history: {
      totalDuration: 0,
      sections: [],
      // sections is [{
      //   sectionLength: SECTION_LENGTH,
      //   duration: sectionDuration,
      //   errors: [{shouldBe: "a", was: "b"}],
      //   createdAt: Date.now(),
      // }],
      errorCount: 0, // total errors, section.errors.length cannot be used because not every section must be saved
      errorWords: {}, // {word: {count: 1, repetitions: 2}
    },
    currentSection: {
      lastInput: -Infinity,
      sectionDuration: 0,
      sectionPos: 0,
      sectionErrors: [],
    },
  };
  await db.put(newChapter);
  window.location.href = `practice.html?id=${_id}`;
}

// ========================
// index.html - add or choose collection
// ========================

function toggleOpenChooseCollection() {
  const userSettings = getUserSettings();
  possiblyOpenChooseCollection(!userSettings.isChooseCollectionOpen);
  userSettings.save();
}

function addCollectionInputChanged() {
  const collectionName = document.getElementById(
    "newCollectionNameInput"
  ) as HTMLInputElement;
  const createCollectionButton = document.getElementById(
    "createCollectionButton"
  ) as HTMLButtonElement;
  if (collectionName.value.length > 0) {
    createCollectionButton.disabled = false;
  } else {
    createCollectionButton.disabled = true;
  }
}

// ========================
// test chapter
// ========================

async function startAppTest() {
  // get text from server
  const res = await fetch("/exampleChapter.json");
  const exampleChapter = await res.json();
  await createCollection(exampleChapter.title);
  saveChapter(exampleChapter.chapter);
}

export { possiblyOpenChooseCollection, addCurrentChaptersToPage };
