import { Chapter, getChapter, HistorySection } from "./other/chapter";
import { getAllDocs, getDb } from "./other/db";
import { getCurrentWord } from "./practice";
import { getUserSettings } from "./other/userSettings";
import {
  calcAccuracy,
  calcTotalAccuracyForChapter,
  calcTotalWpmForChapter,
  calcWpm,
  calcPercentageComplete,
  cleanText,
  formatDuration,
  updateWordCount,
} from "./other/utils";

function historySelectCollection() {
  const userSettings = getUserSettings();
  const collectionSelect = document.getElementById(
    "collectionSelect"
  ) as HTMLSelectElement;
  userSettings.historySelectedCollection = collectionSelect.value;
  userSettings.save();
  renderHistoryPage();
}

function getCombinedValsFromCollection(collection: { chapters: Chapter[] }) {
  let totalDuration = 0;
  let totalErrorCount = 0;
  let totalCharCount = 0;
  let totalTypedCharCount = 0;
  collection.chapters.forEach((chapter: Chapter) => {
    totalDuration += chapter.history.totalDuration;
    totalErrorCount += chapter.history.errorCount;
    totalCharCount += chapter.text.length;
    totalTypedCharCount += chapter.position + chapter.currentWordPosition;
  });
  return {
    totalDuration,
    totalErrorCount,
    totalCharCount,
    totalTypedCharCount,
  };
}

async function addChaptersToPage() {
  const settings = getUserSettings();
  const allDocs = await getAllDocs(true);
  const selectedCollection = settings.historySelectedCollection;
  const collectionIdToNameMap: {
    [key: string]: { name: string; chapters: Chapter[] };
  } = {};
  allDocs.rows.forEach((row) => {
    // todo typing
    if (row.doc && (row.doc as any).type === "collection") {
      collectionIdToNameMap[row.doc._id] = {
        name: (row.doc as any).title,
        chapters: [],
      };
    }
  });
  collectionIdToNameMap["noCollection"] = {
    name: "No Collection",
    chapters: [],
  };
  allDocs.rows.forEach((row) => {
    if (row.doc && (row.doc as any).type === "chapter") {
      if (
        ((row.doc as any).collectionId && selectedCollection === "all") ||
        selectedCollection === (row.doc as any).collectionId
      ) {
        if (collectionIdToNameMap[(row.doc as any).collectionId]) {
          collectionIdToNameMap[(row.doc as any).collectionId].chapters.push(
            row.doc as Chapter
          );
        } else {
          collectionIdToNameMap["noCollection"].chapters.push(
            row.doc as Chapter
          );
        }
      } else if (selectedCollection === "all") {
        collectionIdToNameMap["noCollection"].chapters.push(row.doc as Chapter);
      }
    }
  });
  const chapterListContainer = document.getElementById(
    "chapterListContainer"
  ) as HTMLDivElement;
  if (!chapterListContainer) return; // no data yet, don't render
  chapterListContainer.innerHTML = "";
  Object.keys(collectionIdToNameMap).forEach((collectionKey) => {
    const collection = collectionIdToNameMap[collectionKey];
    const {
      totalDuration,
      totalErrorCount,
      totalCharCount,
      totalTypedCharCount,
    } = getCombinedValsFromCollection(collection);

    if (collection.chapters.length === 0) return;

    const start = `
    <div class="collectionPreview">
      <h3>${collection.name}</h3>
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>WPM/Accuracy</th>
            <th>Words</th>
            <th>Progress</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        `;
    let middle = "";
    collection.chapters.forEach((chapter) => {
      middle += `
          <tr>
            <td>
              <a class="border-radius" href="practice.html?id=${chapter._id}">${
        chapter.title
      }</a>
            </td>
            <td>${calcTotalWpmForChapter(
              chapter
            )} / ${calcTotalAccuracyForChapter(chapter)}%</td>
            <td>${new Intl.NumberFormat().format(chapter.text.length / 5)}</td>
            <td>${calcPercentageComplete(
              chapter.position + chapter.currentWordPosition,
              chapter.text.length
            )}%</td>
            <td>
              <button
                class="secondaryButton mb-02"
                onclick="openEditChapterModal('${chapter._id}')"
              >
                edit
              </button>
              |
              <button
                class="secondaryButton secondaryDeleteButton mb-02"
                onclick="openDeleteChapterModal({ 
                  title: '${chapter.title
                    .replaceAll('"', '\\"')
                    .replaceAll("'", "\\'")}', 
                _id: '${chapter._id}',
                 collectionName: '${collection.name
                   .replaceAll('"', '\\"')
                   .replaceAll("'", "\\'")}' })"
              >
                delete
              </button>
            </td>
          </tr>
          `;
    });
    const total = `
          <tr class="spacer"></tr>
          <tr>
            <td>
              <div class="p-05">
               <a class="border-radius" href="practice.html">Total</a>
              </div>
            </td>
            <td>${calcWpm(totalTypedCharCount, totalDuration)} / ${calcAccuracy(
      totalErrorCount,
      totalTypedCharCount
    )}%</td>
            <td>${new Intl.NumberFormat().format(totalCharCount / 5)}</td>
            <td>${calcPercentageComplete(
              totalTypedCharCount,
              totalCharCount
            )}%</td>
            <td></td>
          </tr>
          `;
    const end = `
        </tbody>
      </table>
    </div>`;
    const html = start + middle + total + end;
    chapterListContainer.innerHTML += html;
  });
}

function combineDatapoints(values: number[]) {
  const userSettings = getUserSettings();
  const chartCombine = Number(userSettings.chartCombine) / 20;
  if (!chartCombine) return values;
  const combined = [];
  for (let i = 0; i < values.length; i += chartCombine) {
    let sum = 0;
    if (i + chartCombine <= values.length) {
      for (let j = 0; j < chartCombine; j++) {
        sum += Number(values[i + j]);
      }
      combined.push(sum / chartCombine);
    }
  }
  return combined;
}

async function renderChart() {
  const userSettings = getUserSettings();
  const allDocs = await getAllDocs();
  const collectionIdToDisplay = userSettings.historySelectedCollection;
  const sections: HistorySection[] = [];
  let countChars = 0; // cannot use sections.length because it ignores typed chars that are not saved into sections
  let countErrors = 0;
  let totalDuration = 0;
  let collectionName = "all";
  allDocs.rows.forEach((row) => {
    if (
      collectionIdToDisplay === "all" &&
      row.doc &&
      (row.doc as any).type === "chapter"
    ) {
      sections.push(...(row.doc as any).history.sections);
      countChars +=
        (row.doc as any).position + (row.doc as any).currentWordPosition;
      countErrors += (row.doc as any).history.errorCount;
      totalDuration += (row.doc as any).history.totalDuration;
    } else if (
      row.doc &&
      (row.doc as any).type === "chapter" &&
      (row.doc as any).collectionId === collectionIdToDisplay
    ) {
      sections.push(...(row.doc as any).history.sections);
      countChars +=
        (row.doc as any).position + (row.doc as any).currentWordPosition;
      countErrors += (row.doc as any).history.errorCount;
      totalDuration += (row.doc as any).history.totalDuration;
    } else if (
      row.doc &&
      (row.doc as any).type === "collection" &&
      row.doc._id === collectionIdToDisplay
    ) {
      collectionName = (row.doc as any).title;
    }
  });

  // hide history if no data
  if (sections.length === 0) {
    document.getElementById(
      "historyContainer"
    )!.innerHTML = `<h3 class="text-center">No history yet. Start typing to see your progress!</h3>`;
    return;
  }

  let runningDuration = 0;
  let wpm: number[] = [];
  let durations: number[] = [];
  let accuracy: number[] = [];
  sections.forEach((section) => {
    wpm.push((section.sectionLength / 5 / section.duration) * 60);
    runningDuration += section.duration;
    durations.push(runningDuration);
    //@ts-ignore - TODO fix this
    accuracy.push(calcAccuracy(section.errors.length, section.sectionLength));
  });

  wpm = combineDatapoints(wpm);
  durations = combineDatapoints(durations);
  accuracy = combineDatapoints(accuracy);
  //@ts-ignore - TODO fix this
  durations = durations.map((duration) => formatDuration(duration));
  const wpmChart = document.getElementById("mainChart") as HTMLDivElement;

  const layout = {
    yaxis: {
      title: "Accuracy",
      side: "left",
      gridcolor: userSettings.isDarkMode ? "#525252" : "",
      fixedrange: true,
      overlaying: "y2",
      showgrid: false,
      ticksuffix: "%",
      tickprefix: "    ",
    },
    yaxis2: {
      title: "WPM",
      side: "right",
      gridcolor: userSettings.isDarkMode ? "#525252" : "",
      fixedrange: true,
    },
    font: {
      color: userSettings.isDarkMode ? "lightgray" : "black",
    },
    xaxis: {
      gridcolor: userSettings.isDarkMode ? "#525252" : "",
      fixedrange: true,
    },
    showlegend: true,
    legend: {
      x: 1.05,
    },
    margin: {
      t: 30,
      b: 90,
    },
    plot_bgcolor: "rgba(0,0,0,0)",
    paper_bgcolor: userSettings.isDarkMode ? "#484848" : "#f3f4f6",
  };

  const trace1 = {
    x: durations,
    y: wpm,
    name: "WPM",
    type: "scatter",
    mode: "lines+markers",
    yaxis: "y2",
    line: {
      color: "#26ae60",
      width: 1,
    },
  };

  const trace2 = {
    x: durations,
    y: accuracy,
    name: "Accuracy",
    type: "scatter",
    mode: "lines+markers",
    yaxis: "y",
    line: {
      color: "#0369a1",
      width: 1,
    },
  };

  const data = [];
  if (userSettings.chartDisplayWpm) {
    data.push(trace1);
  }
  if (userSettings.chartDisplayAccuracy) {
    data.push(trace2);
  }
  //@ts-ignore - TODO fix this
  Plotly.newPlot(wpmChart, data, layout, {
    displayModeBar: false,
    responsive: true,
    showTips: false,
  });

  document.getElementById(
    "statsHeader"
  )!.innerText = `Stats: ${collectionName}`;
  const statsTotalTime = document.getElementById("statsTotalTime");
  statsTotalTime!.innerHTML = formatDuration(totalDuration);
  const statsTotalWords = document.getElementById("statsTotalWords");
  statsTotalWords!.innerHTML = `${new Intl.NumberFormat().format(
    countChars / 5
  )} words`;
  const statsTotalWpm = document.getElementById("statsTotalWpm");
  statsTotalWpm!.innerHTML = `${calcWpm(countChars, totalDuration)} wpm`;
  const statsTotalAccuracy = document.getElementById("statsTotalAccuracy");
  statsTotalAccuracy!.innerHTML = `${calcAccuracy(countErrors, countChars)}%`;
}

function renderHistoryPage() {
  renderChart();
  addChaptersToPage();
}

// ========================
// history.html - chapters
// ========================

function openDeleteChapterModal({
  title,
  _id,
  collectionName,
}: {
  title: string;
  _id: string;
  collectionName: string;
}) {
  const modal = document.getElementById("deleteChapterModal");
  const deleteButton = document.getElementById("confirmDeleteChapterButton");
  const chapterTitleSpan = document.getElementById("chapterTitle");
  const collectionNameSpan = document.getElementById("chapterCollectionName");
  modal!.classList.remove("hidden");
  chapterTitleSpan!.innerHTML = title;
  collectionNameSpan!.innerHTML = collectionName;
  deleteButton!.setAttribute("onclick", `deleteChapter("${_id}")`);
  setTimeout(() => {
    deleteButton!.focus();
  }, 0);
}

function closeDeleteChapterModal() {
  const modal = document.getElementById("deleteChapterModal");
  modal!.classList.add("hidden");
}

async function deleteChapter(_id: string) {
  const db = getDb();
  const doc = await getChapter(_id);
  if (doc) await db.remove(doc);
  closeDeleteChapterModal();
  renderHistoryPage();
}

async function openEditChapterModal(_id: string) {
  const allDocs = await getAllDocs();
  const modal = document.getElementById("editChapterModal") as HTMLDivElement;
  const chapterContent = document.getElementById(
    "chapterInputArea"
  ) as HTMLDivElement;
  const editButton = document.getElementById(
    "confirmEditChapterButton"
  ) as HTMLButtonElement;
  const collectionSelect = document.getElementById(
    "editCollectionSelect"
  ) as HTMLSelectElement;
  editButton.setAttribute("onclick", `editChapter("${_id}")`);
  modal.classList.remove("hidden");

  // add collections to select
  let options = document.querySelectorAll("#editCollectionSelect option");
  options.forEach((option) => option.remove());

  allDocs.rows.forEach((row) => {
    if (row.doc && (row.doc as any).type === "collection") {
      const option = document.createElement("option");
      option.value = row.doc._id;
      option.text = (row.doc as any).title;
      collectionSelect.add(option);
    }
  });

  // no collection
  const option = document.createElement("option");
  option.value = "";
  option.text = "";
  collectionSelect.add(option);

  const chapter = await getChapter(_id);
  if (!chapter) return;
  collectionSelect.value = chapter.collectionId;
  chapterContent.innerHTML =
    "<div>" + chapter.text.replaceAll("\n", "</div><div>") + "</div>";
  updateWordCount();
}

function closeEditChapterModal() {
  const modal = document.getElementById("editChapterModal") as HTMLDivElement;
  modal.classList.add("hidden");
}

async function editChapter(_id: string) {
  const chapterContent = document.getElementById(
    "chapterInputArea"
  ) as HTMLDivElement;
  const resetChapterCheckbox = document.getElementById(
    "resetChapterCheckbox"
  ) as HTMLInputElement;
  const chapter = await getChapter(_id);
  if (!chapter) return;
  chapter.text = cleanText(chapterContent.innerText);
  chapter.title = cleanText(chapterContent.innerText.split("\n")[0]);
  if (chapter.position + chapter.currentWordPosition > chapter.text.length) {
    chapter.position = chapter.text.length;
    chapter.currentWordPosition = 0;
  } else {
    while (
      chapter.text[chapter.position - 1] !== " " &&
      chapter.text[chapter.position - 1] !== "\n" &&
      chapter.position - 1 >= 0
    ) {
      chapter.position--;
    }
    chapter.currentWord = getCurrentWord(chapter);
    chapter.currentWordPosition = 0;
  }
  if (resetChapterCheckbox.checked) {
    chapter.position = 0;
    chapter.currentWordPosition = 0;
    chapter.currentWord = getCurrentWord(chapter);
    chapter.updatedAt = Date.now();
    chapter.history = {
      totalDuration: 0,
      sections: [],
      errorCount: 0,
      //@ts-ignore - TODO fix
      errorWords: [],
    };
    chapter.currentSection = {
      lastInput: -Infinity,
      sectionDuration: 0,
      sectionPos: 0,
      sectionErrors: [],
    };
    resetChapterCheckbox.checked = false;
  }
  const chosenCollection = (
    document.getElementById("editCollectionSelect") as HTMLInputElement
  ).value;
  chapter.collectionId = chosenCollection;
  const db = getDb();
  await db.put(chapter);
  closeEditChapterModal();
  renderHistoryPage();
}

if (window.location.href.includes("history.html")) {
  document.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      if (document.getElementById("deleteChapterModal")) {
        closeDeleteChapterModal();
      }
      if (document.getElementById("editChapterModal")) {
        closeEditChapterModal();
      }
    }
  });
}

export { renderHistoryPage };
