import { closeNavMenu, isNavMenuOpen } from "./navMenu";
import { getUserSettings } from "./userSettings";

let isSettingsMenuOpen = false;

document.addEventListener("click", function (event) {
  if (isNavMenuOpen) {
    if (
      (event.target as HTMLElement).closest("#menu") ||
      (event.target as HTMLElement).closest("#menuButtonOpen")
    ) {
      return;
    }
    closeNavMenu();
  }
  if (isSettingsMenuOpen) {
    if (
      (event.target as HTMLElement).closest("#settingsMenu") ||
      (event.target as HTMLElement).closest("#settingsButtonToggle")
    ) {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement!.blur();
      }
      return;
    }
    closeSettingsMenu();
  }
  if (document.activeElement instanceof HTMLElement) {
    if (
      document.activeElement.tagName === "BUTTON" ||
      document.activeElement.tagName === "A"
    ) {
      document.activeElement.blur();
    }
  }
});

function closeSettingsMenu() {
  const settingsMenu = document.getElementById("settingsMenu");
  if (settingsMenu) {
    settingsMenu.classList.add("hidden");
    isSettingsMenuOpen = false;
  }
}

function openSettingsMenu() {
  document.getElementById("settingsMenu")!.classList.remove("hidden");
  isSettingsMenuOpen = true;
}

function toggleSettingsMenu() {
  if (isSettingsMenuOpen) {
    closeSettingsMenu();
  } else {
    openSettingsMenu();
  }
}

// todo - refactor into inline event listeners
const userSettings = getUserSettings();

const displayStatsCheckbox = document.getElementById("displayStats");
const statsDiv = document.getElementById("stats");
if (displayStatsCheckbox) {
  displayStatsCheckbox.addEventListener("change", (event) => {
    if (statsDiv) {
      if (
        event.currentTarget &&
        (event.currentTarget as HTMLInputElement).checked
      ) {
        statsDiv.classList.remove("hidden");
      } else {
        statsDiv.classList.add("hidden");
      }
    }
    userSettings.displayStats = (
      event.currentTarget as HTMLInputElement
    ).checked;
    userSettings.save();
  });
}

const toggleDarkModeCheckbox = document.getElementById("toggleDarkMode");
if (toggleDarkModeCheckbox) {
  toggleDarkModeCheckbox.addEventListener("change", (event) => {
    if ((event.currentTarget as HTMLInputElement).checked) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    userSettings.isDarkMode = (event.currentTarget as HTMLInputElement).checked;
    userSettings.save();
  });
}

const toggleAudioCue = document.getElementById("toggleAudioCue");
if (toggleAudioCue) {
  toggleAudioCue.addEventListener("change", (event) => {
    userSettings.audioCue = (event.currentTarget as HTMLInputElement).checked;
    userSettings.save();
  });
}

const togglePracticeErrors = document.getElementById("togglePracticeErrors");
const errorPracticeRepetitions = document.getElementById(
  "errorPracticeRepetitions"
);
const errorPracticeAutodelete = document.getElementById(
  "errorPracticeAutodelete"
);
if (togglePracticeErrors) {
  togglePracticeErrors.addEventListener("change", (event) => {
    userSettings.practiceErrors = (
      event.currentTarget as HTMLInputElement
    ).checked;
    userSettings.save();
    if (errorPracticeRepetitions) {
      if (userSettings.practiceErrors) {
        errorPracticeRepetitions.removeAttribute("disabled");
        errorPracticeAutodelete!.removeAttribute("disabled");
      } else {
        errorPracticeRepetitions.setAttribute("disabled", "true");
        errorPracticeAutodelete!.setAttribute("disabled", "true");
      }
    }
  });
}

const fullscreenButton = document.getElementById("fullscreenButton");
if (fullscreenButton) {
  fullscreenButton.addEventListener("click", () => {
    const requestMethod =
      document.documentElement.requestFullscreen ||
      // @ts-ignore
      document.documentElement.requestFullScreen ||
      // @ts-ignore
      document.documentElement.webkitRequestFullScreen ||
      // @ts-ignore
      document.documentElement.mozRequestFullScreen ||
      // @ts-ignore
      document.documentElement.msRequestFullScreen;
    if (requestMethod) {
      requestMethod.call(document.documentElement);
      closeNavMenu();
    }
  });
}

export { closeSettingsMenu };
