import { getDb } from "./db";

type CurrentSection = {
  lastInput: number;
  sectionDuration: number;
  sectionPos: number;
  sectionErrors: {
    shouldBe: string;
    was: string;
  }[];
};

type HistorySection = {
  sectionLength: number;
  duration: number;
  errors: {
    shouldBe: string;
    was: string;
  }[];
  createdAt: number;
};

type ErrorWords = {
  [word: string]: {
    count: number;
    repetitions: number;
    uniquePractices: number;
  };
};

type Chapter = {
  _id: string;
  _rev: string;
  collectionId: string;
  text: string;
  currentWord: string;
  position: number;
  currentWordPosition: number;
  lastWord: string;
  title: string;
  type: "chapter";
  createdAt: number;
  updatedAt: number;
  history: {
    totalDuration: number;
    sections: HistorySection[];
    errorCount: number;
    errorWords: ErrorWords;
  };
  currentSection: CurrentSection;
};

let chapter: Chapter;
const getChapter = async (_id?: string) => {
  if (chapter && !_id) return chapter;
  if (!_id) {
    console.error("No id provided to getChapter");
    return;
  }
  const db = getDb();
  chapter = await db.get(_id);
  return chapter;
};

export type { Chapter, HistorySection, ErrorWords };
export { getChapter };
