import { getUserSettings, applyUserSettings } from "./userSettings";
import { getChapter } from "./chapter";
import { getDb } from "./db";
import { getAllDocs } from "./db";
import {
  addCurrentChaptersToPage,
  possiblyOpenChooseCollection,
} from "../index";
import { addCollectionsToCollectionSelect } from "./collection";
import {
  getCurrentWord,
  initStatsDiv,
  practice,
  updateText,
} from "../practice";
import {
  addInstallButton,
  registerServiceWorker,
  updateAccountStatus,
} from "../settings";
import { renderHistoryPage } from "../history";
import { possiblyDisplayError } from "../loginSignup";

function applyDarkMode(isDarkMode: boolean) {
  if (isDarkMode) {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }
  document.documentElement.classList.remove("hidden");
}

window.onload = async () => {
  const userSettings = getUserSettings();
  applyDarkMode(userSettings.isDarkMode);
  getDb(); // init db

  window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", (event) => {
      if (event.matches) {
        userSettings.isDarkMode = true;
      } else {
        userSettings.isDarkMode = false;
      }
      userSettings.save();
      applyUserSettings();
    });

  if (
    window.location.href.includes("index.html") ||
    !window.location.href.includes("html")
  ) {
    registerServiceWorker();
    addCollectionsToCollectionSelect();
    possiblyOpenChooseCollection(userSettings.isChooseCollectionOpen);
    addCurrentChaptersToPage();
  } else if (window.location.href.includes("practice.html")) {
    const chapterId = window.location.search.split("=")[1];
    const chapter = await getChapter(chapterId);
    if (!chapter) {
      console.error("No chapter found");
      return;
    }
    chapter.currentWord = getCurrentWord(chapter);
    if (chapter) {
      initStatsDiv(chapter);
      updateText(chapter);
    }
    document.getElementById("cursor")!.scrollIntoView();
    practice();
  } else if (window.location.href.includes("settings.html")) {
    registerServiceWorker();
    addInstallButton();
    const allDocs = await getAllDocs();
    addCollectionsToCollectionSelect();
    updateAccountStatus();
  } else if (window.location.href.includes("history.html")) {
    addCollectionsToCollectionSelect();
    renderHistoryPage();
  } else if (
    window.location.href.includes("login.html") ||
    window.location.href.includes("signup.html")
  ) {
    possiblyDisplayError();
  }
  applyUserSettings();
};

export {};
