type UserSettings = {
  displayStats: boolean;
  isDarkMode: boolean;
  isChooseCollectionOpen: boolean;
  selectedCollection: string;
  historySelectedCollection: string;
  chartDisplayWpm: boolean;
  chartDisplayAccuracy: boolean;
  chartCombine: number;
  practiceErrors: boolean;
  errorPracticeRepetitions: number;
  errorPracticeAutodelete: boolean;
  audioCue: boolean;
  isSynced: boolean;
  isPWAInstalled: boolean;
  save: () => void;
};

let userSettings: UserSettings;
function getUserSettings() {
  if (userSettings) return userSettings;
  userSettings = JSON.parse(localStorage.getItem("settings") ?? "null") ?? {
    displayStats: true,
    isDarkMode: true,
    isChooseCollectionOpen: false,
    selectedCollection: "",
    historySelectedCollection: "all",
    chartDisplayWpm: true,
    chartDisplayAccuracy: true,
    chartCombine: 20,
    practiceErrors: false,
    errorPracticeRepetitions: 3,
    errorPracticeAutodelete: true,
    audioCues: false,
    isSynced: false,
    isPWAInstalled: false,
  };
  userSettings.save = function () {
    localStorage.setItem("settings", JSON.stringify(this));
  };
  return userSettings;
}

function applyUserSettings() {
  const userSettings = getUserSettings();

  // practice.html
  if (window.location.href.includes("practice.html")) {
    const displayStatsCheckbox = document.getElementById("displayStats");
    const statsDiv = document.getElementById("stats");

    if (displayStatsCheckbox) {
      (displayStatsCheckbox as HTMLInputElement).checked =
        userSettings.displayStats;
      if (statsDiv) {
        if (userSettings.displayStats) {
          statsDiv.classList.remove("hidden");
        } else {
          statsDiv.classList.add("hidden");
        }
      }
    }
  }

  // settings.html
  if (
    window.location.href.includes("settings.html") ||
    window.location.href.includes("practice.html")
  ) {
    const errorPracticeRepetitions = document.getElementById(
      "errorPracticeRepetitions"
    ) as HTMLInputElement;
    const errorPracticeAutodelete = document.getElementById(
      "errorPracticeAutodelete"
    ) as HTMLInputElement;
    const toggleDarkModeCheckbox = document.getElementById(
      "toggleDarkMode"
    ) as HTMLInputElement;
    const toggleAudioCue = document.getElementById(
      "toggleAudioCue"
    ) as HTMLInputElement;
    const togglePracticeErrors = document.getElementById(
      "togglePracticeErrors"
    ) as HTMLInputElement;

    if (errorPracticeRepetitions) {
      errorPracticeRepetitions.value =
        userSettings.errorPracticeRepetitions + "";
    }
    if (errorPracticeAutodelete) {
      errorPracticeAutodelete.checked = userSettings.errorPracticeAutodelete;
    }
    if (toggleDarkModeCheckbox) {
      toggleDarkModeCheckbox.checked = userSettings.isDarkMode;
    }
    if (toggleAudioCue) {
      toggleAudioCue.checked = userSettings.audioCue;
    }
    if (togglePracticeErrors) {
      togglePracticeErrors.checked = userSettings.practiceErrors;
      if (errorPracticeRepetitions) {
        if (userSettings.practiceErrors) {
          errorPracticeRepetitions.removeAttribute("disabled");
          errorPracticeAutodelete.removeAttribute("disabled");
        } else {
          errorPracticeRepetitions.setAttribute("disabled", "true");
          errorPracticeAutodelete.setAttribute("disabled", "true");
        }
      }
    }
  }

  // history.html
  if (window.location.href.includes("history.html")) {
    const chartDisplayWpmToggle = document.getElementById(
      "chartDisplayWpm"
    ) as HTMLInputElement;
    const chartDisplayAccuracyToggle = document.getElementById(
      "chartDisplayAccuracy"
    ) as HTMLInputElement;
    const chartCombine = document.getElementById(
      "chartCombine"
    ) as HTMLInputElement;
    const historyCollectionSelect = document.getElementById(
      "collectionSelect"
    ) as HTMLInputElement;

    chartDisplayWpmToggle.checked = userSettings.chartDisplayWpm;
    chartDisplayAccuracyToggle.checked = userSettings.chartDisplayAccuracy;
    chartCombine.value = userSettings.chartCombine + "";
    historyCollectionSelect.value = userSettings.historySelectedCollection;
  }
}

export type { UserSettings };
export { getUserSettings, applyUserSettings };
