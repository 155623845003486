import { Chapter } from "./chapter";
import { getAllDocs, getDb } from "./db";
import { getUserSettings } from "./userSettings";

type Collection = {
  _id: string;
  _rev: string;
  title: string;
  type: "collection";
  createdAt: number;
  updatedAt: number;
};

let isAddCollectionInputOpen = false;

async function addCollectionsToCollectionSelect() {
  const collectionSelect = document.getElementById(
    "collectionSelect"
  ) as HTMLSelectElement;
  let collectionExists = false;
  const allDocs = await getAllDocs();
  allDocs.rows.forEach((row) => {
    const doc = row.doc as unknown as Chapter | Collection;
    if (doc.type === "collection") {
      collectionExists = true;
      const option = document.createElement("option");
      option.value = doc._id;
      option.text = doc.title;
      collectionSelect.add(option);
    }
  });
  if (collectionExists) {
    collectionSelect.classList.remove("hidden");
    if (window.location.href.includes("settings.html")) {
      document
        .getElementById("deleteCollectionButton")!
        .classList.remove("hidden");
    }
  } else {
    collectionSelect.classList.add("hidden");
    if (window.location.href.includes("settings.html")) {
      document
        .getElementById("deleteCollectionButton")!
        .classList.add("hidden");
    }
  }
}

function openAddCollection() {
  const collectionSelectDiv = document.getElementById(
    "collectionSelectDiv"
  ) as HTMLDivElement;
  const addCollectionDiv = document.getElementById(
    "addCollectionDiv"
  ) as HTMLDivElement;
  const addChapterButton = document.getElementById(
    "addChapterButton"
  ) as HTMLButtonElement;

  const newCollectionNameInput = document.getElementById(
    "newCollectionNameInput"
  ) as HTMLInputElement;

  addCollectionDiv.classList.remove("hidden");
  collectionSelectDiv.classList.add("hidden");
  if (window.location.href.includes("settings.html")) {
    const openAddCollectionButton = document.getElementById(
      "openAddCollectionButton"
    ) as HTMLButtonElement;
    openAddCollectionButton.classList.add("hidden");
  } else {
    // is index.html
    addChapterButton.disabled = true;
  }
  newCollectionNameInput.focus();
  isAddCollectionInputOpen = true;
}

function closeAddCollectionModal() {
  const collectionNameInput = document.getElementById(
    "newCollectionNameInput"
  ) as HTMLInputElement;
  const collectionSelectDiv = document.getElementById(
    "collectionSelectDiv"
  ) as HTMLDivElement;
  const addCollectionDiv = document.getElementById(
    "addCollectionDiv"
  ) as HTMLDivElement;
  const addChapterButton = document.getElementById(
    "addChapterButton"
  ) as HTMLButtonElement;
  collectionNameInput.value = "";
  addCollectionDiv.classList.add("hidden");
  collectionSelectDiv.classList.remove("hidden");
  if (window.location.href.includes("settings.html")) {
    // is settings.html
    const openAddCollectionButton = document.getElementById(
      "openAddCollectionButton"
    ) as HTMLButtonElement;
    openAddCollectionButton.classList.remove("hidden");
    // is index.html
  } else {
    if (
      (document.getElementById("chapterInputArea") as HTMLDivElement).innerText
        .length > 0 &&
      (
        document.getElementById("chapterInputArea") as HTMLDivElement
      ).innerText.trim() !== "paste your text here... (e.g. a book chapter)"
    ) {
      addChapterButton.disabled = false;
    }
  }
  isAddCollectionInputOpen = false;
}

async function createCollection(newCollectionName = "") {
  const collectionSelect = document.getElementById(
    "collectionSelect"
  ) as HTMLSelectElement;
  const db = getDb();

  let collectionName = newCollectionName;
  if (!newCollectionName) {
    collectionName = (
      document.getElementById("newCollectionNameInput") as HTMLInputElement
    ).value;
  }
  let _id;
  let alreadyExists = false;
  [...collectionSelect.options].forEach((o) => {
    if (o.text === collectionName) {
      _id = o.value;
      alreadyExists = true;
    }
  });
  _id = _id ? _id : new Date().toISOString();
  if (!alreadyExists) {
    const newCollection = {
      _id,
      title: collectionName,
      type: "collection",
    };
    await db.put(newCollection);
    if (window.location.href.includes("settings.html")) {
      (
        document.getElementById("deleteCollectionButton") as HTMLButtonElement
      ).classList.remove("hidden");
    }
    collectionSelect.classList.remove("hidden");
    const option = document.createElement("option");
    option.value = _id;
    option.text = collectionName;
    collectionSelect.add(option);
  }
  collectionSelect.value = _id;
  selectCollection();
  closeAddCollectionModal();
}

function selectCollection() {
  const userSettings = getUserSettings();
  const collectionSelect = document.getElementById(
    "collectionSelect"
  ) as HTMLSelectElement;
  userSettings.selectedCollection = collectionSelect.value;
  userSettings.save();
}

export type { Collection };
export {
  isAddCollectionInputOpen,
  addCollectionsToCollectionSelect,
  createCollection,
};
