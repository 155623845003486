import { Chapter, getChapter } from "./other/chapter";
import { saveChapterToDB } from "./other/db";
import { closeNavMenu, isNavMenuOpen } from "./other/navMenu";
import { getUserSettings } from "./other/userSettings";
import {
  calcAccuracy,
  calcTotalAccuracyForChapter,
  calcTotalWpmForChapter,
  calcWpm,
  formatDuration,
} from "./other/utils";

// TODO: only call document.getElementById if current page is chapter.html - wrap all this inside a function?

const SECTION_LENGTH = 100;

async function practice() {
  const chapter = await getChapter();
  if (!chapter) {
    console.error("No chapter found");
    return;
  }
  const userSettings = getUserSettings();
  const errorAudio = new Audio("./assets/audio/error.mp3");
  const cursor = document.getElementById("cursor") as HTMLSpanElement;
  window.addEventListener("beforeunload", () => saveChapterToDB(true));
  const ignoreList = [
    "Dead",
    "Escape",
    "Shift",
    "Meta",
    "Control",
    "Alt",
    "CapsLock",
    "Tab",
    "Backspace",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
  ];
  let errors: string[] = [];

  if (!chapter) {
    console.error("No chapter found");
    return;
  }
  // this is only necessary if a user has added a chapter before this feature was added
  if (!chapter.currentSection) {
    chapter.currentSection = {
      lastInput: -Infinity,
      sectionDuration: 0,
      sectionPos: 0,
      sectionErrors: [],
    };
  }

  let isPracticeErrorsActive = false;

  let wasChangedSinceLastSave = false;
  setInterval(() => {
    if (isPracticeErrorsActive) {
      return;
    }
    const now = Date.now();
    if (now - chapter.currentSection.lastInput < 2000) {
      chapter.history.totalDuration += 1;
      chapter.currentSection.sectionDuration += 1;
      updateTotalDurationSpan(chapter);
      updateTotalWpmSpan(chapter);
      updateTotalAccuracySpan(chapter);
      updateTotalProgressSpan(chapter);
      wasChangedSinceLastSave = true;
    } else if (
      now - chapter.currentSection.lastInput > 3000 &&
      wasChangedSinceLastSave
    ) {
      saveChapterToDB();
      wasChangedSinceLastSave = false;
    }
  }, 1000);

  let finished = chapter.position === chapter.text.length - 1; // -1 because a space was added after the last word
  if (finished) {
    cursor.classList.add("hidden");
    const returnHomeLink = document.getElementById(
      "returnHomeLink"
    ) as HTMLAnchorElement;
    returnHomeLink.classList.remove("hidden");
    returnHomeLink.focus();
  }

  let numToPractice = userSettings.errorPracticeRepetitions - 1; // -1 because it will be increased on first call
  let wasIncreased = false;

  function incNumToPractice() {
    if (!wasIncreased && numToPractice < 10) {
      numToPractice += 1;
      cursor.classList.remove("practice" + (numToPractice - 1));
      cursor.classList.add("practice" + numToPractice);
      wasIncreased = true;
    }
  }

  function decNumToPractice(chapter: Chapter) {
    numToPractice -= 1;
    wasIncreased = false;
    chapter.history.errorWords[chapter.currentWord].repetitions += 1;
    if (numToPractice === -1 && userSettings.errorPracticeAutodelete) {
      endPracticeErrors();
    } else {
      cursor.classList.remove("practice" + (numToPractice + 1));
      cursor.classList.add("practice" + numToPractice);
    }
  }

  const pausedStatsDiv = document.getElementById(
    "pausedStats"
  ) as HTMLDivElement;
  function endPracticeErrors() {
    isPracticeErrorsActive = false;
    cursor.classList.remove("practice" + (numToPractice + 1));
    document.getElementById("currentWord")!.style.color =
      "var(--highlight-color)";

    numToPractice = userSettings.errorPracticeRepetitions - 1;
    pausedStatsDiv.classList.add("hidden");
    return;
  }

  function startPracticeErrors(e: KeyboardEvent, chapter: Chapter) {
    isPracticeErrorsActive = true;
    chapter.history.errorWords[chapter.currentWord].uniquePractices += 1;
    document.getElementById("currentWord")!.style.color =
      "var(--error-practice-color)";
    cursor.style.color = "var(--highlight-color)";
    pausedStatsDiv.classList.remove("hidden");
    practiceErrors(e, chapter);
  }

  function practiceErrors(e: KeyboardEvent, chapter: Chapter) {
    if (e.key === " ") {
      if (
        document.activeElement &&
        document.activeElement.tagName === "INPUT"
      ) {
        return;
      }
      e.preventDefault();
    }

    if (e.key === "Backspace" || e.key === "ArrowLeft") {
      if (chapter.currentWordPosition > 0 || errors.length > 0) {
        if (e.altKey) {
          const lastEnter = errors.lastIndexOf("&#9166;");
          const lastSpace = errors.lastIndexOf("&#9251;");
          const lastSpaceInsideErrors =
            lastEnter > lastSpace ? lastEnter : lastSpace;
          if (lastSpaceInsideErrors !== -1) {
            errors = errors.slice(0, lastSpaceInsideErrors);
            updateWrongInputSpan(errors);
          } else {
            errors = [];
            if (chapter.position + chapter.currentWordPosition === 0) {
              chapter.position = 0;
              chapter.currentWordPosition = 0;
              chapter.currentWord = getCurrentWord(chapter);
              updateWrongInputSpan(errors);
              return;
            }
            const inc = chapter.currentWordPosition === 0 ? 1 : 0;
            const lastEnter = chapter.text
              .slice(0, chapter.position + chapter.currentWordPosition + inc)
              .lastIndexOf("\n");
            const lastSpace = chapter.text
              .slice(0, chapter.position + chapter.currentWordPosition + inc)
              .lastIndexOf(" ");
            const delStop = lastEnter > lastSpace ? lastEnter : lastSpace;
            if (delStop !== -1) {
              chapter.position = delStop + 1;
              chapter.currentWordPosition = 0;
              chapter.currentWord = getCurrentWord(chapter);
            } else {
              chapter.position = 0;
              chapter.currentWordPosition = 0;
              chapter.currentWord = getCurrentWord(chapter);
            }
            wasIncreased = false;
            updateWrongInputSpan(errors);
            if (numToPractice === 0) {
              endPracticeErrors();
            }
            updateText(chapter);
          }
        } else {
          if (errors.length > 0) {
            errors.pop();
            updateWrongInputSpan(errors);
            return;
          } else {
            deleteChar(chapter);
            updateText(chapter);
            if (chapter.currentWordPosition === 0 && numToPractice === 0) {
              endPracticeErrors();
            }
            return;
          }
        }
      } else if (chapter.currentWordPosition === 0) {
        incNumToPractice();
        return;
      }
    }

    if (ignoreList.includes(e.key)) {
      if (isNavMenuOpen && e.key === "Escape") {
        closeNavMenu();
      }
      return;
    }

    const temp = chapter.currentWord.slice(
      chapter.currentWordPosition,
      chapter.currentWordPosition + 4
    );
    const isCorrectLTClick = e.key === "<" && temp === "&lt;";
    const isCorrectGTClick = e.key === ">" && temp === "&gt;";
    const notSpace = e.key !== " " && e.key !== "Enter"; // last char of currentWord is space

    const wordFinished =
      chapter.currentWordPosition === chapter.currentWord.length - 1;
    const correctSpace =
      e.key === " " && wordFinished && userSettings.errorPracticeAutodelete;

    const correctWordAutoFinish =
      (userSettings.errorPracticeAutodelete &&
        e.key === " " &&
        chapter.text[chapter.position + chapter.currentWordPosition] == "\n") ||
      correctSpace;

    const isCorrectClick =
      (errors.length === 0 &&
        (notSpace || correctSpace) &&
        e.key ===
          chapter.text[chapter.position + chapter.currentWordPosition]) ||
      isCorrectLTClick ||
      isCorrectGTClick ||
      correctWordAutoFinish;

    if (isCorrectClick) {
      let posInc = 1;
      if (isCorrectLTClick || isCorrectGTClick) {
        posInc = 4;
      }
      if (!correctSpace) {
        chapter.currentWordPosition += posInc;
      }
      if (chapter.currentWordPosition === chapter.currentWord.length - 1) {
        decNumToPractice(chapter);
      }
      if (userSettings.errorPracticeAutodelete && wordFinished) {
        const inc = chapter.currentWordPosition === 0 ? 1 : 0;
        const lastEnter = chapter.text
          .slice(0, chapter.position + chapter.currentWordPosition + inc)
          .lastIndexOf("\n");
        const lastSpace = chapter.text
          .slice(0, chapter.position + chapter.currentWordPosition + inc)
          .lastIndexOf(" ");
        const delStop = lastEnter > lastSpace ? lastEnter : lastSpace;
        if (delStop !== -1) {
          chapter.position = delStop + 1;
          chapter.currentWordPosition = 0;
          chapter.currentWord = getCurrentWord(chapter);
        } else {
          chapter.position = 0;
          chapter.currentWordPosition = 0;
          chapter.currentWord = getCurrentWord(chapter);
        }
        wasIncreased = false;
        updateText(chapter);
      }
    } else {
      if (userSettings.audioCue) {
        errorAudio.play();
      }
      if (e.key === " ") {
        errors.push("&#9251;");
      } else if (e.key === "Enter") {
        errors.push("&#9166;");
      } else {
        errors.push(e.key);
      }
      updateWrongInputSpan(errors);
      incNumToPractice();
    }
    updateText(chapter);
  }

  window.addEventListener("keydown", (e) => {
    chapter.currentSection.lastInput = Date.now();
    if (finished) {
      return;
    }
    if (isPracticeErrorsActive) {
      practiceErrors(e, chapter);
      return;
    }
    if (e.key === "ArrowDown" && e.altKey) {
      e.preventDefault();
      cursor.scrollIntoView();
    }
    if (e.key === " ") {
      if (
        document.activeElement &&
        document.activeElement.tagName === "INPUT"
      ) {
        return;
      }
      e.preventDefault();
    }
    if (e.key === "Backspace" || e.key === "ArrowLeft") {
      if (
        chapter.position + chapter.currentWordPosition > 0 ||
        errors.length > 0
      ) {
        if (e.altKey) {
          const lastEnter = errors.lastIndexOf("&#9166;");
          const lastSpace = errors.lastIndexOf("&#9251;");
          const lastSpaceInsideErrors =
            lastEnter > lastSpace ? lastEnter : lastSpace;
          // inc is used to prevent the cursor from moving back when the user has arrows before the word but uses alt+backspace to delete the word
          let inc =
            chapter.currentWordPosition === 0 && errors.length !== 0 ? 0 : -1;
          if (lastSpaceInsideErrors !== -1) {
            errors = errors.slice(0, lastSpaceInsideErrors);
            updateWrongInputSpan(errors);
          } else {
            errors = [];
            if (chapter.position + chapter.currentWordPosition === 0) {
              chapter.position = 0;
              chapter.currentWordPosition = 0;
              chapter.currentWord = getCurrentWord(chapter);
              updateWrongInputSpan(errors);
              return;
            }
            const lastEnter = chapter.text
              .slice(0, chapter.position + chapter.currentWordPosition + inc)
              .lastIndexOf("\n");
            const lastSpace = chapter.text
              .slice(0, chapter.position + chapter.currentWordPosition + inc)
              .lastIndexOf(" ");
            const delStop = lastEnter > lastSpace ? lastEnter : lastSpace;
            if (delStop !== -1) {
              chapter.position = delStop + 1;
              chapter.currentWordPosition = 0;
              chapter.currentWord = getCurrentWord(chapter);
            } else {
              chapter.position = 0;
              chapter.currentWordPosition = 0;
              chapter.currentWord = getCurrentWord(chapter);
            }
            updateWrongInputSpan(errors);
            updateText(chapter);
          }
        } else {
          if (errors.length > 0) {
            errors.pop();
            updateWrongInputSpan(errors);
            return;
          } else {
            deleteChar(chapter);
            updateText(chapter);
            return;
          }
        }
      }
    }
    if (ignoreList.includes(e.key)) {
      if (isNavMenuOpen && e.key === "Escape") {
        closeNavMenu();
      }
      return;
    }

    const isCorrectEnterClick =
      e.key == "Enter" &&
      chapter.text[chapter.position + chapter.currentWordPosition] === "\n";
    const temp = chapter.currentWord.slice(
      chapter.currentWordPosition,
      chapter.currentWordPosition + 4
    );
    const isCorrectLTClick = e.key === "<" && temp === "&lt;";
    const isCorrectGTClick = e.key === ">" && temp === "&gt;";

    const isCorrectClick =
      errors.length === 0 &&
      (e.key === chapter.text[chapter.position + chapter.currentWordPosition] ||
        isCorrectEnterClick ||
        isCorrectLTClick ||
        isCorrectGTClick);

    if (isCorrectClick) {
      chapter.currentSection.sectionPos += 1;
      updateNextSectionSpan(chapter);
      let posInc = 1;
      if (isCorrectLTClick || isCorrectGTClick) {
        posInc = 4;
      }
      chapter.currentWordPosition += posInc;

      // highlight next word if current char is a space
      const currentChar =
        chapter.text[chapter.position + chapter.currentWordPosition - posInc];
      if (currentChar === " " || currentChar === "\n" || !currentChar) {
        chapter.lastWord = chapter.currentWord;
        chapter.position += chapter.lastWord.length;
        chapter.currentWord = getCurrentWord(chapter);
        chapter.currentWordPosition = 0;

        // Remove highlight if the last word of the text was typed
      } else if (
        chapter.position + chapter.currentWordPosition ===
        chapter.text.length - 1 // -1 because a space was added after the last word
      ) {
        cursor.classList.add("hidden");
        const returnHomeLink = document.getElementById(
          "returnHomeLink"
        ) as HTMLAnchorElement;
        returnHomeLink.classList.remove("hidden");
        returnHomeLink.focus();
        finished = true;
        chapter.lastWord = chapter.currentWord;
        chapter.position += chapter.lastWord.length;
        chapter.currentWord = "";
        chapter.currentWordPosition = 0;
      }

      // update chapter.history
      if (chapter.currentSection.sectionPos === SECTION_LENGTH) {
        chapter.history.sections.push({
          sectionLength: SECTION_LENGTH,
          duration: chapter.currentSection.sectionDuration,
          errors: chapter.currentSection.sectionErrors,
          createdAt: Date.now(),
        });
        chapter.currentSection.sectionErrors = [];
        chapter.currentSection.sectionPos = 0;
        chapter.currentSection.sectionDuration = 0;
        updateWpmSpan(chapter);
        updateAccuracySpan(chapter);
      }
      updateText(chapter);
    } else {
      if (
        // ignore if the user is holding down a modifier key, e.g. reloading the page
        !e.metaKey &&
        !e.ctrlKey &&
        !e.altKey &&
        (!document.activeElement || document.activeElement.tagName !== "BUTTON")
      ) {
        chapter.currentSection.sectionErrors.push({
          shouldBe:
            chapter.text[
              chapter.position + chapter.currentWordPosition + errors.length
            ],
          was: e.key,
        });
        const errorWordExists = chapter.history.errorWords[chapter.currentWord];
        if (!errorWordExists) {
          chapter.history.errorWords[chapter.currentWord] = {
            count: 1,
            repetitions: 0,
            uniquePractices: 0,
          };
        } else {
          chapter.history.errorWords[chapter.currentWord].count += 1;
        }
        chapter.history.errorCount++;
        if (userSettings.practiceErrors) {
          startPracticeErrors(e, chapter);
          return;
        }
        if (userSettings.audioCue) {
          errorAudio.play();
        }
        if (e.key === " ") {
          errors.push("&#9251;");
        } else if (e.key === "Enter") {
          errors.push("&#9166;");
        } else {
          errors.push(e.key);
        }
        updateWrongInputSpan(errors);
      }
    }
  });
}

const highlightSpan = document.getElementById("highlight") as HTMLSpanElement;
const currentWordRestSpan = document.getElementById(
  "currentWordRest"
) as HTMLSpanElement;
const restSpan = document.getElementById("rest") as HTMLSpanElement;
const currentWordHighlightSpan = document.getElementById(
  "currentWordHighlight"
) as HTMLSpanElement;

function updateText(chapter: Chapter) {
  highlightSpan.innerHTML = chapter.text.slice(0, chapter.position);
  currentWordHighlightSpan.innerHTML = chapter.currentWord
    .slice(0, chapter.currentWordPosition)
    .replaceAll("\n", '<span class="lineBreakMarker">&#9166</span>\n');
  currentWordRestSpan.innerHTML = chapter.currentWord
    .slice(chapter.currentWordPosition)
    .replaceAll("\n", '<span class="lineBreakMarker">&#9166</span>\n');
  restSpan.innerHTML = chapter.text
    .slice(chapter.position + chapter.currentWord.length)
    .replaceAll("\n", '<span class="lineBreakMarker">&#9166</span>\n');
}

const wrongInputSpan = document.getElementById("wrongInput") as HTMLSpanElement;
function updateWrongInputSpan(errors: string[]) {
  wrongInputSpan.innerHTML = errors.join("");
}

function getCurrentWord(chapter: Chapter) {
  // next word includes the space or linebreak after it
  const currentWordEndPossibility1 = chapter.text.indexOf(
    " ",
    chapter.position
  );
  const currentWordEndPossibility2 = chapter.text.indexOf(
    "\n",
    chapter.position
  );

  let currentWordEnd;
  if (currentWordEndPossibility1 === -1) {
    currentWordEnd = currentWordEndPossibility2;
  } else if (currentWordEndPossibility2 === -1) {
    currentWordEnd = currentWordEndPossibility1;
  } else {
    currentWordEnd = Math.min(
      currentWordEndPossibility1,
      currentWordEndPossibility2
    );
  }
  if (currentWordEnd === currentWordEndPossibility2) {
    // hacky stuff, #currentWord is display: inline-block to correctly position the cursor.
    // This removes the linebreak which gets re-added using the css class .newLine the css ::before pseudo element
    if (restSpan) {
      restSpan.classList.add("newLine");
    }
  } else {
    if (restSpan) {
      restSpan.classList.remove("newLine");
    }
  }
  if (currentWordEnd === -1) {
    currentWordEnd = chapter.text.length;
  } else {
    currentWordEnd += 1;
  }
  let currentWord = chapter.text.slice(chapter.position, currentWordEnd);
  return currentWord;
}

function getPreviousWord(chapter: Chapter) {
  // previous word includes the space before it
  let prevWordEnd = chapter.position - 1;

  let prevWordStartPossibility1 = chapter.text.lastIndexOf(
    " ",
    prevWordEnd - 1
  );
  let prevWordStartPossibility2 = chapter.text.lastIndexOf(
    "\n",
    prevWordEnd - 1
  );

  let prevWordStart;
  if (prevWordStartPossibility1 === -1) {
    prevWordStart = prevWordStartPossibility2;
  } else if (prevWordStartPossibility2 === -1) {
    prevWordStart = prevWordStartPossibility1;
  } else {
    prevWordStart = Math.max(
      prevWordStartPossibility1,
      prevWordStartPossibility2
    );
  }

  if (chapter.text[prevWordEnd] === "\n") {
    // hacky stuff, #currentWord is display: inline-block to correctly position the cursor.
    // This removes the linebreak which gets re-added using the css class .newLine the css ::before pseudo element
    restSpan.classList.add("newLine");
  } else {
    restSpan.classList.remove("newLine");
  }

  let prevWord = "";
  if (prevWordEnd === chapter.text.length - 1) {
    prevWord = chapter.text.slice(prevWordStart + 1);
  } else {
    prevWord = chapter.text.slice(prevWordStart + 1, prevWordEnd + 1);
  }
  return prevWord;
}

function deleteChar(chapter: Chapter) {
  if (chapter.currentWordPosition === 0) {
    chapter.lastWord = chapter.currentWord;
    chapter.currentWord = getPreviousWord(chapter);
    chapter.position -= chapter.currentWord.length;
    chapter.currentWordPosition = chapter.currentWord.length - 1;
  } else if (chapter.currentWordPosition >= 0) {
    const temp = chapter.currentWord.slice(
      chapter.currentWordPosition - 4,
      chapter.currentWordPosition
    );
    const isLTorGT = temp === "&lt;" || temp === "&gt;";
    chapter.currentWordPosition = isLTorGT
      ? chapter.currentWordPosition - 4
      : chapter.currentWordPosition - 1;
  }
  return chapter;
}

// ========================
// practice.html - stats block
// ========================

const totalDurationSpan = document.getElementById(
  "totalDuration"
) as HTMLDivElement;
function updateTotalDurationSpan(chapter: Chapter) {
  totalDurationSpan.innerHTML = formatDuration(chapter.history.totalDuration);
}

const wpmSpan = document.getElementById("wpm") as HTMLDivElement;
function updateWpmSpan(chapter: Chapter) {
  const lastSection =
    chapter.history.sections[chapter.history.sections.length - 1];
  if (lastSection) {
    wpmSpan.innerHTML = `${calcWpm(
      lastSection.sectionLength,
      lastSection.duration
    )} wpm`;
  } else {
    wpmSpan.innerHTML = `0.00 wpm`;
  }
}

const accuracySpan = document.getElementById("accuracy") as HTMLDivElement;
function updateAccuracySpan(chapter: Chapter) {
  const lastSection =
    chapter.history.sections[chapter.history.sections.length - 1];
  if (lastSection) {
    accuracySpan.innerHTML = `${calcAccuracy(
      lastSection.errors.length,
      lastSection.sectionLength
    )} %`;
  } else {
    accuracySpan.innerHTML = `0.00 %`;
  }
}

const nextWpmSpan = document.getElementById("nextWpm") as HTMLDivElement;
function updateNextSectionSpan(chapter: Chapter) {
  nextWpmSpan.innerHTML = `${chapter.currentSection.sectionPos}/${SECTION_LENGTH}`;
}

const totalWpmSpan = document.getElementById("totalWpm") as HTMLDivElement;
function updateTotalWpmSpan(chapter: Chapter) {
  totalWpmSpan.innerHTML = `${calcTotalWpmForChapter(chapter)} wpm`;
}

const totalAccuracySpan = document.getElementById(
  "totalAccuracy"
) as HTMLDivElement;
function updateTotalAccuracySpan(chapter: Chapter) {
  totalAccuracySpan.innerHTML = `${calcTotalAccuracyForChapter(chapter)} %`;
}

const totalProgressSpan = document.getElementById(
  "totalProgress"
) as HTMLDivElement;
function updateTotalProgressSpan(chapter: Chapter) {
  totalProgressSpan.innerHTML = `${Math.floor(
    (chapter.position + chapter.currentWordPosition) / 5
  )}/${Math.floor(chapter.text.length / 5)} words`;
}

function initStatsDiv(chapter: Chapter) {
  //left
  updateTotalDurationSpan(chapter); // 1s timeout

  //middle
  updateWpmSpan(chapter); // section finished
  updateAccuracySpan(chapter); // section finished
  updateNextSectionSpan(chapter); // each correct keypress

  //right
  updateTotalWpmSpan(chapter); // 1s timeout
  updateTotalAccuracySpan(chapter); // 1s timeout
  updateTotalProgressSpan(chapter); // 1s timeout
}

export { initStatsDiv, getCurrentWord, updateText, practice };
