import { getUserSettings } from "./userSettings";
import {
  addCurrentChaptersToPage,
  possiblyOpenChooseCollection,
} from "../index";
import { getCookie } from "./utils";
import { updateAccountStatus } from "../settings";
import { addCollectionsToCollectionSelect, Collection } from "./collection";
import { Chapter, getChapter } from "./chapter";

// type AllDocs = {
//   doc?:
//     | PouchDB.Core.ExistingDocument<PouchDB.Core.AllDocsMeta>
//     | {
//         doc?:
//           | PouchDB.Core.ExistingDocument<PouchDB.Core.AllDocsMeta>
//           | Chapter
//           | undefined;
//         id: string;
//         key: string;
//         value: {
//           rev: string;
//           deleted?: boolean | undefined;
//         };
//       }
//     | {
//         doc?:
//           | PouchDB.Core.ExistingDocument<PouchDB.Core.AllDocsMeta>
//           | Collection
//           | undefined;
//         id: string;
//         key: string;
//         value: {
//           rev: string;
//           deleted?: boolean | undefined;
//         };
//       }
//     | undefined;
//   id: string;
//   key: string;
//   value: {
//     rev: string;
//     deleted?: boolean | undefined;
//   };
// }[];

let db: PouchDB.Database<{}>;
let allDocs: PouchDB.Core.AllDocsResponse<{}>;
function getDb() {
  if (db) {
    return db;
  }
  const userSettings = getUserSettings();
  db = new PouchDB("fics");
  const userhex = getCookie("userhex");
  if (userhex) {
    const url =
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1"
        ? "http://127.0.0.1:5984"
        : `https://${window.location.hostname}/couch`;
    var remoteDB = new PouchDB(`${url}/userdb-${userhex}`, {
      fetch: function (url, opts) {
        opts!.credentials = "include";
        return PouchDB.fetch(url, opts);
      },
    });

    let replicationError = false;
    db.replicate
      .from(remoteDB)
      .on("complete", async function () {
        if (
          window.location.href.includes("index.html") ||
          !window.location.href.includes(".html")
        ) {
          allDocs = await getAllDocs(true);
          addCollectionsToCollectionSelect();
          possiblyOpenChooseCollection(userSettings.isChooseCollectionOpen);
          addCurrentChaptersToPage();
          console.log("Sync from remote: complete");
        }
        db.sync(remoteDB)
          .on("complete", function () {
            if (!replicationError) {
              userSettings.isSynced = true;
              userSettings.save();
              updateAccountStatus();
              console.log("Sync to remote: complete");
            } else {
              console.log("Sync to remote: complete, but error");
            }
          })
          .on("denied", function (err: {}) {
            console.error("Sync to remote: denied", err);
            replicationError = true;
            userSettings.isSynced = false;
            userSettings.save();
            updateAccountStatus();
          })
          .on("error", function (err: {}) {
            replicationError = true;
            userSettings.isSynced = false;
            userSettings.save();
            updateAccountStatus();
            console.error("Sync to remote: Error syncing from remote", err);
          });
      })
      .on("denied", function (err) {
        replicationError = true;
        userSettings.isSynced = false;
        userSettings.save();
        updateAccountStatus();
        console.error("Replication: Denied syncing from remote", err);
      })
      .on("error", function (err) {
        replicationError = true;
        userSettings.isSynced = false;
        userSettings.save();
        updateAccountStatus();
        console.error("Replication: Error syncing from remote", err);
      });
  }
  return db;
}

async function getAllDocs(force = false) {
  if (allDocs && !force) {
    return allDocs;
  }
  allDocs = await db.allDocs({ include_docs: true });
  return allDocs;
}

async function saveChapterToDB(force = false) {
  const chapter = await getChapter();
  if (!chapter) {
    console.error("No chapter found");
    return;
  }
  chapter.updatedAt = Date.now();
  if (window.requestIdleCallback && !force) {
    window.requestIdleCallback(async () => {
      const res = await db.put(chapter);
      chapter._rev = res.rev;
    });
  } else {
    const res = await db.put(chapter);
    chapter._rev = res.rev;
  }
}

export { getDb, getAllDocs, saveChapterToDB };
