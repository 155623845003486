import { Chapter } from "./chapter";
import { isAddCollectionInputOpen } from "./collection";

function getCookie(cookieName: string) {
  let cookie: { [key: string]: string } = {};
  document.cookie.split(";").forEach(function (el) {
    let [key, value] = el.split("=");
    cookie[key.trim()] = value;
  });
  return cookie[cookieName];
}

function calcPercentageComplete(
  typedCharCount: number,
  totalCharCount: number
) {
  if (totalCharCount === 0 || typedCharCount === 0) return "00.00";
  const percentage = (typedCharCount / totalCharCount) * 100;
  const pad = percentage < 10 ? "0" : "";
  return pad + percentage.toFixed(2);
}

function calcWpm(charCount: number, duration: number) {
  if (duration === 0) return "00.00";
  return ((charCount / duration / 5) * 60).toFixed(2);
}

function calcTotalWpmForChapter(chapter: Chapter) {
  return calcWpm(
    chapter.position + chapter.currentWordPosition,
    chapter.history.totalDuration
  );
}

function calcTotalAccuracyForChapter(chapter: Chapter) {
  return calcAccuracy(
    chapter.history.errorCount,
    chapter.position + chapter.currentWordPosition
  );
}

function calcAccuracy(errorCount: number, charCount: number) {
  if (charCount === 0) return "00.00";
  return (100 - (errorCount / charCount) * 100).toFixed(2);
}

function formatDuration(textStoreDuration: number) {
  const hours = Math.floor(textStoreDuration / 3600);
  const minutes = ("0" + Math.floor((textStoreDuration % 3600) / 60)).slice(-2);
  const seconds = ("0" + Math.floor(textStoreDuration % 60)).slice(-2);
  return hours > 0
    ? `${hours}:${minutes}:${seconds} h`
    : `${minutes}:${seconds} min`;
}

function updateWordCount(charCount = 0) {
  const addChapterButton = document.getElementById(
    "addChapterButton"
  ) as HTMLButtonElement;
  const wordCount = document.getElementById("wordCount") as HTMLDivElement;
  charCount = charCount
    ? charCount
    : document.getElementById("chapterInputArea")!.innerText.length;
  wordCount.innerHTML = `${Math.round(charCount / 5)} words`;
  if (addChapterButton) {
    if (charCount === 0 || isAddCollectionInputOpen) {
      addChapterButton.disabled = true;
    } else {
      addChapterButton.disabled = false;
    }
  }
}

function cleanText(text: string) {
  const cleaned =
    text
      .replace(/[\u2018\u2019]/g, "'")
      .replace(/[\u201C\u201D]/g, '"')
      .replace(/[\u2013\u2014]/g, "-")
      .replace(/[\u2026]/g, "...")
      .replace(
        /[\u00A0\u1680​\u180e\u2000-\u2009\u200a​\u200b​\u202f\u205f​\u3000]/g,
        " "
      )
      .replace(new RegExp(/[àáâãäå]/g), "a")
      .replace(new RegExp(/æ/g), "ae")
      .replace(new RegExp(/ç/g), "c")
      .replace(new RegExp(/[èéêë]/g), "e")
      .replace(new RegExp(/[ìíîï]/g), "i")
      .replace(new RegExp(/ñ/g), "n")
      .replace(new RegExp(/[òóôõö]/g), "o")
      .replace(new RegExp(/œ/g), "oe")
      .replace(new RegExp(/[ùúûü]/g), "u")
      .replace(new RegExp(/[ýÿ]/g), "y")
      .replaceAll(" \n", "\n")
      .replaceAll("<", "&lt;")
      .replaceAll(">", "&gt;")
      .replaceAll("~", "-")
      .replaceAll("·", "-")
      .replaceAll("  ", " ")
      .trim() + " "; // add space to end to make sure autodelete works because currentWord includes space after the word
  return cleaned;
}

export {
  calcAccuracy,
  calcPercentageComplete,
  calcTotalAccuracyForChapter,
  calcTotalWpmForChapter,
  calcWpm,
  cleanText,
  formatDuration,
  getCookie,
  updateWordCount,
};
